const SET_BUSY = 'SET_BUSY';

export default (previousState = false, { type, payload }) => {
  if (type === SET_BUSY) {
    return payload;
  }
  return previousState;
};

export const setBusy = (payload) => {
  return {
    type: SET_BUSY,
    payload,
  };
};
