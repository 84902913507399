import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import fr from 'ra-language-french';

// domain translations
import custom from 'i18n';

const _ = require('lodash');

const messages = {
  fr: _.merge(fr, custom.fr),
  en: _.merge(en, custom.en),
};

//console.log('i18nProvider', { en, fr, messages, custom });

export default polyglotI18nProvider((locale) => {
  const output = messages[locale];
  //console.log('i18nProvider', { locale, messages, output });
  return output;
});
