export default {
  ra: {
    auth: {
      password: 'Account password',
      username: 'Account email',

      login_button: 'Login',
      login_invalid: 'Invalid email or password',
      login_forgot_link: 'Forgot Password?',
      login_intro: 'Wecome to WeClearJunk',
      login_title: 'Login Required',
      login_remember: 'Remember me',
      login_register_link: 'Register now',
      login_success: "You're logged in",
      login_error: 'Login error',

      register_button: 'Register Now',
      register_invalid: 'Invalid information',
      register_title: 'Register Now',
      register_login_link: 'Already have an account? Login here',
      register_success: 'Registration confirmed!',
      register_error: 'Registration error',

      forgot_title: 'Forgot Your Password?',
      forgot_send_link: 'Send Link',
      forgot_back_to_login: 'Back to Login',
      forgot_no_account_register: 'No account? Sign Up',

      reset_button: 'Reset password',
      reset_invalid: 'Invalid information',
      reset_unknown_error: 'Unknown error, please contact us',
      reset_account_not_found: 'Did not find account with that email',
      reset_email_sent: 'Password reset link sent to your email',
      reset_title: 'Reset Password',
      reset_success: 'Password reset!',
      reset_error: 'Could not reset password',
    },
    dashboard: {
      no_upcoming_jobs: 'No Jobs Scheduled',
      book_future_jobs: 'Would you like to book something now?',
    },
    page: {
      book: {
        title: 'Book Now',
        button: 'Request Booking',
        success: 'Request sent!',
        invalid: 'Invalid information',
        error: 'Error',
        cant_close_dialog_while_working: 'Please wait, working',
        repeat: {
          title: 'Repeat Booking',
          button: 'Request Booking',
        },
      },
      contact: {
        title: 'Contact Us',
        button: 'Send Message',
        success: 'Message sent!',
        invalid: 'Invalid information',
        error: 'Error',
      },

      help: {
        button: 'Get Help Now',
        invalid: 'Invalid information',
        title: 'Request Help',
        success: 'Message sent!',
        error: 'Error',
      },

      wastereport: {
        button: 'Download Now',
        invalid: 'Invalid information',
        title: 'Get Waste Report',
        success: 'Opening report now',
        error: 'Registration error',
        switch_to_custom_copy: `Can't find the exact report you need? Don't worry. Click here to request a custom report. We have the data, we'll produce any report you need.`,
        switch_to_custom_button: 'Get a Custom Report',
        custom_mode_title: 'Request Custom Report',
      },
      job: {
        document: {
          download: {
            success: 'Document has downloaded',
          },
        },
      },
    },
    error: {
      name_required: 'Name required',
      email_required: 'Email required',
      mobile_required: 'Mobile required',
      message_required: 'Message required',
      password_mismatch: 'Passwords did not match',
    },
    menu: {
      title: 'Menu',
      delegations: 'Sharing',
    },
  },
};
