import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { useTranslate, UserMenu, MenuItemLink } from 'react-admin';

import { useUser } from '@clerk/clerk-react';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
//import ConfigIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { InstallPromptMenuItem } from 'components/InstallPrompt';

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
});

const MyCustomIcon = connect()(() => {
  const classes = useStyles();

  //  // get user from state, this should be enough
  //  const userFromState = useSelector((state) => state.user);
  //  // but can't figure out how to dispatch yet, so, do it the long way
  //  const { u: userId } = Token.get() || { userFromState };
  //  console.log('MyCustomIcon', { userFromClerk, userFromState, userId });
  //  const { loading, error, data } = useQueryWithStore({
  //    type: 'getOne',
  //    resource: 'profile',
  //    payload: { id: userId },
  //  });
  //  if (loading) return ''; // <Loading />;
  //  if (error) return ''; // <Error />;
  //  if (!data) return null;

  const { user } = useUser() || {};
  const md5 = window.CryptoJS.MD5(user.email).toString();
  const img = `https://www.gravatar.com/avatar/${md5}`;
  //console.log('MyCustomIcon state', { user, md5, img });

  return <Avatar className={classes.avatar} src={img} />;
});

export const ShortcutsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return [
    <MenuItemLink
      ref={ref}
      key="contact"
      to="/contact"
      primaryText={translate('ra.menu.contact', { _: 'Contact' })}
      leftIcon={<EmailIcon />}
      onClick={onClick} // Close the menu on click
    />,
  ];
});

// eslint-disable-next-line react/display-name
export const SettingsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/settings"
      primaryText={translate('ra.menu.settings', { _: 'Settings' })}
      leftIcon={<SettingsIcon />}
      onClick={onClick} // close the menu on click
    />
  );
});

// eslint-disable-next-line react/display-name
export const DelegationsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/delegations"
      primaryText={translate('ra.menu.delegations', { _: 'Delegations' })}
      leftIcon={<GroupIcon />}
      onClick={onClick} // close the menu on click
    />
  );
});

export const MyUserMenu = (props) => (
  <UserMenu icon={<MyCustomIcon />} {...props}>
    <InstallPromptMenuItem />
    <ShortcutsMenu />
    {/* <SettingsMenu /> */}
    <DelegationsMenu />
  </UserMenu>
);
