import React, { useEffect } from 'react';
import Config from './lib/config';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useUser } from '@clerk/clerk-react';

import { Admin, Resource } from 'react-admin';

import './lib/security';
import authProvider from './lib/authProvider';
import dataProvider from './lib/dataProvider';
import i18nProvider from './lib/i18nProvider';
import * as async from './lib/async';

import JobIcon from '@material-ui/icons/LocalShipping';
import CalendarIcon from '@material-ui/icons/EventOutlined';
import ItemIcon from '@material-ui/icons/ToysOutlined';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

import 'lib/install';

import { MyTheme } from './theme/MyTheme';
import { MyLayout } from './theme/MyLayout';
import { MyLogoutButton } from './theme/MyLogout';

import history from './lib/history';
// NB: doesn't work in multi-tenant cross-domain apps

import customRoutes from './routes';
import customReducers from './reducers';

//console.log('Config', { Config });

const Login = async.Page('Login');
const Dashboard = async.Page('Dashboard');

const Invoice = async.Resource('Invoice');
const InvoiceJob = async.Resource('InvoiceJob');
const InvoiceItem = async.Resource('InvoiceItem');
const InvoiceWaste = async.Resource('InvoiceWaste');
const InvoiceWastereport = async.Resource('InvoiceWastereport');
const InvoiceJobEvent = async.Resource('InvoiceJobEvent');
import 'react-block-ui/style.css';
import 'App.scss';

export const App = () => {
  //console.log('App()');
  /*
  if (typeof window !== 'undefined') {
    const { hash = '', pathname = '', search = '' } = window.location;
    const basepath = window.REACT_APP_BASE_PATH || '/';
    useEffect(() => {
      console.log('App() landing', { pathname, hash, search });
      if (pathname !== basepath) {
        const hashify = '/#' + pathname + search;
        console.log('App() landing hashify', { hashify });
        window.location = hashify;
      }
    }, [hash, search, pathname]);
    if (pathname !== basepath) {
      console.log('App() abort: do not render until we have a has', { hash });
      return 'Please wait...';
    }
  }
  */

  const clerkUser = useUser();
  const { user } = useUser() || {};
  const { id: clerkUserId, externalId: fyneworksUserId } = user || {};
  useEffect(() => {
    if (fyneworksUserId) return;
    console.warn('TODO: must sync user with fyneorks', {
      clerkUserId,
      fyneworksUserId,
      clerkUser,
    });
  }, [clerkUserId, fyneworksUserId]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={MyTheme}>
        <CssBaseline />
        <style>{`
            @keyframes spin {
                 0% { transform: rotate(360deg); }
                 100% { transform: rotate(0deg); }
            }
        `}</style>
        <Admin
          history={history}
          // NB: doesn't work in multi-tenant cross-domain apps

          theme={MyTheme}
          layout={MyLayout}
          logout={MyLogoutButton}
          loginPage={Login}
          dashboard={Dashboard}
          initialState={{}}
          authProvider={authProvider(Config)}
          dataProvider={dataProvider(Config)}
          i18nProvider={i18nProvider}
          //locale={typeof window !== 'undefined' ? resolveBrowserLocale() : undefined}
          customRoutes={customRoutes}
          customReducers={customReducers}
        >
          <Resource name="wcj.dashboard.stats" />

          <Resource
            name="wcj.invoice.job.calendar"
            options={{ label: 'Calendar' }}
            icon={CalendarIcon}
            list={InvoiceJobEvent.List}
            edit={InvoiceJobEvent.Show}
          />
          <Resource
            name="wcj.invoice.job"
            options={{ label: 'Jobs' }}
            icon={JobIcon}
            show={InvoiceJob.Show}
            list={InvoiceJob.List}
          />
          <Resource
            name="wcj.invoice"
            options={{ label: 'Invoices' }}
            icon={InvoiceIcon}
            show={Invoice.Show}
            list={Invoice.List}
          />
          <Resource name="wcj.invoice.component" />
          <Resource name="wcj.invoice.signature" />
          <Resource name="wcj.invoice.image" />
          <Resource name="wcj.invoice.audio" />
          <Resource name="wcj.invoice.video" />
          <Resource name="wcj.invoice.track" />
          <Resource name="wcj.invoice.record" />
          <Resource name="wcj.invoice.material" />
          <Resource
            name="wcj.invoice.waste"
            options={{ label: 'Items Collected' }}
            icon={ItemIcon}
            show={InvoiceWaste.Show}
            list={InvoiceWaste.List}
          />
          <Resource
            name="wcj.invoice.wastereport"
            options={{ label: 'Waste Data' }}
            icon={ItemIcon}
            show={InvoiceWastereport.Show}
            list={InvoiceWastereport.List}
          />
          <Resource name="wcj.invoice.coupon" />
          <Resource name="wcj.invoice.discount" />
          <Resource name="wcj.invoice.refund" />
          <Resource name="wcj.invoice.transaction" />
          <Resource name="wcj.invoice.billination" />
          <Resource name="wcj.invoice.destination" />
          <Resource name="wcj.invoice.item" show={InvoiceItem.Show} />

          <Resource name="wcj.invoice.folder" />
          <Resource name="wcj.invoice.sector" />
          <Resource name="wcj.invoice.department" />

          {/* <Resource name="wcj.quote" options={{label: "Quotes"}} icon={QuoteIcon} show={Quote.Show} list={Quote.List} />  */}
          <Resource name="wcj.quote" />
          <Resource name="wcj.quote.component" />
          <Resource name="wcj.quote.signature" />
          <Resource name="wcj.quote.image" />
          <Resource name="wcj.quote.audio" />
          <Resource name="wcj.quote.video" />
          <Resource name="wcj.quote.job" />
          <Resource name="wcj.quote.item" />
          <Resource name="wcj.quote.coupon" />
          <Resource name="wcj.quote.discount" />
          <Resource name="wcj.quote.billination" />
          <Resource name="wcj.quote.destination" />

          <Resource name="wcj.page" />
          <Resource name="wcj.page.album" />
          <Resource name="wcj.page.attachment" />
          <Resource name="wcj.page.audio" />
          <Resource name="wcj.page.brand" />
          <Resource name="wcj.page.brochure" />
          <Resource name="wcj.page.collection" />
          <Resource name="wcj.page.colour" />
          <Resource name="wcj.page.comment" />
          <Resource name="wcj.page.download" />
          <Resource name="wcj.page.faq" />
          <Resource name="wcj.page.file" />
          <Resource name="wcj.page.image" />
          <Resource name="wcj.page.model" />
          <Resource name="wcj.page.price" />
          <Resource name="wcj.page.review" />
          <Resource name="wcj.page.scrap" />
          <Resource name="wcj.page.size" />
          <Resource name="wcj.page.video" />

          <Resource name="wcj.product" />
          <Resource name="wcj.product.album" />
          <Resource name="wcj.product.attachment" />
          <Resource name="wcj.product.audio" />
          <Resource name="wcj.product.brand" />
          <Resource name="wcj.product.brochure" />
          <Resource name="wcj.product.collection" />
          <Resource name="wcj.product.colour" />
          <Resource name="wcj.product.comment" />
          <Resource name="wcj.product.download" />
          <Resource name="wcj.product.faq" />
          <Resource name="wcj.product.file" />
          <Resource name="wcj.product.image" />
          <Resource name="wcj.product.model" />
          <Resource name="wcj.product.price" />
          <Resource name="wcj.product.review" />
          <Resource name="wcj.product.scrap" />
          <Resource name="wcj.product.size" />
          <Resource name="wcj.product.video" />

          <Resource name="wcj.service" />
          <Resource name="wcj.service.option" />

          <Resource name="wcj.account" />
          <Resource name="wcj.account.contact" />
          <Resource name="wcj.account.delegation" />
          <Resource name="wcj.account.delegation.invitation" />
          <Resource name="wcj.account.delegate" />
          <Resource name="wcj.account.delegate.invitation" />
        </Admin>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
