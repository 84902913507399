import React from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

export const useSidebarStyles = makeStyles((theme) => ({
  drawerPaper: {
    //paddingTop: 80,
    // backgroundColor: 'red',
    //overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      marginTop: 80,
    },
    maxWidth: 200, // The default value is 240
  },
}));

export const MySidebar = (props) => {
  const classes = useSidebarStyles();
  return <Sidebar classes={classes} {...props} />;
};
