//console.log('installPrompt setup');

// https://developers.google.com/web/fundamentals/app-install-banners
let deferredPrompt = null;
window.addEventListener('beforeinstallprompt', (e) => {
  console.log('installPrompt captured');

  // Prevent Chrome 76 and later from showing the mini-infobar
  e.preventDefault();

  // Stash the event so it can be triggered later.
  window.deferredPrompt = deferredPrompt = e.originalEvent || e;
  // see https://stackoverflow.com/questions/39286385/defer-web-install-banner

  // Update UI notify the user they can add to home screen
  // $('body').addClass('install-prompt-available');
});
window.addEventListener('appinstalled', (evt) => {
  console.log('installPrompt success - app installed', { evt });

  // publish info
  //publish({ action: 'installed' });
  // TODO: pubsub integration
});

// see https://developer.chrome.com/docs/apps/overview/
export const isInstalled = () => {
  if (window.chrome && window.chrome.app && window.chrome.app.runtime) {
    // Running inside a Chrome App context
    return true;
  } else if (
    window.chrome &&
    window.chrome.app &&
    window.chrome.app.isInstalled
  ) {
    // Running inside a Chrome App context
    return true;
  } else if (
    window.chrome &&
    window.chrome.app &&
    window.chrome.app.getIsInstalled
  ) {
    // Running inside a Chrome App context
    return window.chrome.app.getIsInstalled();
  } else {
    // Either not Chrome, or not as an app window
    return false;
  }
};

export const installPromptIsAvailable = () => {
  // pretend it's available on localhost
  if (window.document.location.hostname.match(/(ngrok|localhost|127|192)/))
    return true;

  // console.log('installPrompt invoked');
  if (!deferredPrompt) return; // console.warn("installPrompt was not captured by beforeinstallprompt event");

  // To show the prompt, run this * but only when the user clicks something *
  // deferredPrompt.prompt();
  // The prompt() method must be called with a user gesture
  console.log('installPrompt is available on window.deferredPrompt');

  // return true if an attempt was possible
  return true;
};

export const installPrompt = () =>
  new Promise((resolve, reject) => {
    if (deferredPrompt !== undefined) {
      // The user has had a postive interaction with our app and Chrome
      // has tried to prompt previously, so let's show the prompt.
      deferredPrompt.prompt();

      // Follow what the user has done with the prompt.
      deferredPrompt.userChoice.then(function (choiceResult) {
        console.log(choiceResult.outcome);

        if (choiceResult.outcome == 'dismissed') {
          console.log('User accepted installPrompt');
          resolve(choiceResult);
        } else {
          console.log('User dismissed installPrompt');
          reject(choiceResult);
        }

        // We no longer need the prompt.  Clear it up.
        deferredPrompt = null;
      });
    }
  });

/*

vanilla js implementations

// Show an element
const show = (window.show = function (elem) {
  if (elem.length) [].forEach.call(elem, show);
  if (elem && elem.style) elem.style.display = 'block';
});

// Hide an element
const hide = (window.hide = function (elem) {
  if (elem.length) [].forEach.call(elem, hide);
  if (elem && elem.style) elem.style.display = 'none';
});

// ask user to install
setTimeout(() => {
  const eleToShow = document.getElementsByClassName('install-prompt-available');
  const eleToHide = document.getElementsByClassName(
    'install-prompt-unavailable'
  );

  if (deferredPrompt) {
    // only one time!
    if (window.localStorage && localStorage.getItem('install-prompt') == 'y') {
      return;
    }
    localStorage.setItem('install-prompt', 'y');

    // show hide
    show(eleToShow); // .css.display = 'block';
    hide(eleToHide); // .css.display = 'none';

    try {
      // automatically prompt for install, but only once
      installPrompt(); // fwx.fe.install();
    } catch (e) {
      localStorage.setItem('install-prompt', '');
      console.log('installPrompt failed', { e });
    }
  } else {
    // hide menu item, installl not available
    hide(eleToShow); // .css.display = 'hide';
    show(eleToHide); // .css.display = 'block';
  }
}, 5 * 1000);

window.fwx = window.fwx || {};
window.fwx.fe = window.fwx.fe || {};
window.fwx.fe.install = installPrompt;
*/
