import React from 'react';
import {
  useGetList,
  Loading,
  useDataProvider,
  useNotify,
  useQuery,
  useRedirect,
  Link,
} from 'react-admin';

import { Button } from '@material-ui/core';
import { useUser } from '@clerk/clerk-react';
import { useState } from 'react';

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

export const useDelegations = () => {
  const forceUpdate = useForceUpdate();
  const redirect = useRedirect();
  const { data, isLoading, error, loaded } = useGetList('wcj.account.delegate');
  const arr = (a) => Object.keys(a).map((i) => ({ ...a[i] }));
  if (!data) return { isLoading, error, loaded };

  const activate = (e, delegation) => {
    e.preventDefault();
    if (delegation && delegation.delegation) {
      window.localStorage.setItem('delegation', String(delegation.delegation));
    } else {
      clear();
    }
    forceUpdate();
    redirect('/');
  };
  const clear = () => window.localStorage.removeItem('delegation');

  const delegations = arr(data);
  if (!delegations.length) return { isLoading, error, loaded };

  const stored = window.localStorage.getItem('delegation');
  const matched = delegations.filter(
    (d) => parseInt(d.delegation) === parseInt(stored)
  );
  const current = matched.length === 1 ? matched[0] : null;
  if (!!stored && !current) {
    console.warn('ListDelegations remove', {
      stored,
      matched,
      current,
      delegations,
    });
    window.localStorage.removeItem('delegation');
  }

  return {
    activate,
    isLoading,
    error,
    loaded,
    delegations,
    stored,
    matched,
    current,
  };
};

export const DelegationNotice = ({ ...props }) => {
  const { delegations, current } = useDelegations();
  //console.log('DelegationNotice', { delegations, current });
  if (!delegations) return null;

  return (
    <div
      style={{
        background: '#f5f5f5',
        color: '#000',
        fontWeight: 'bold',
        padding: 10,
        margin: '0 -20px',
        fontSize: 12,
        textAlign: 'center',
      }}
    >
      <Link to="/delegations">
        {!current ? (
          'Your Personal Account'
        ) : (
          <>{current.company || current.name || current.email}</>
        )}
      </Link>
    </div>
  );
};

export const Delegations = () => {
  return (
    <div>
      <p>
        Here at WeClearJunk we understand the challenges of working with a big
        team. So we let you share accounts and collaborate with your colleagues.
      </p>
      <ListDelegations />
      <RequestDelegation />
    </div>
  );
};

export const ListDelegations = () => {
  const { delegations, current, activate } = useDelegations();
  console.log('ListDelegations', { delegations, current });
  if (!delegations) return null;

  console.log('ListDelegations', { delegations, current });
  return (
    <div>
      <strong>Choose an account</strong>
      <ul>
        <li
          key="mine"
          style={{
            padding: '10px 0',
            display: 'grid',
            gridTemplateColumns: 'auto 120px',
            alignContent: 'end',
          }}
        >
          <span>Your account</span>
          <span>
            {!current ? (
              'Active'
            ) : (
              <Button
                variant="contained"
                colour="primary"
                size="small"
                onClick={(e) => activate(e, { delegation: null })}
              >
                Open
              </Button>
            )}
          </span>
        </li>

        {!delegations.length ? (
          <li>
            <NoDelegations />
          </li>
        ) : (
          delegations.map((delegation) => (
            <li
              key={delegation.id}
              style={{
                padding: '10px 0',
                display: 'grid',
                gridTemplateColumns: 'auto 120px',
                alignContent: 'end',
              }}
            >
              <span>
                {delegation.company || delegation.name || delegation.email}
              </span>
              <span>
                {String(current?.delegation) ===
                String(delegation.delegation) ? (
                  'Active'
                ) : (
                  <Button
                    variant="contained"
                    colour="primary"
                    size="small"
                    onClick={(e) => activate(e, { ...delegation })}
                  >
                    Open
                  </Button>
                )}
              </span>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export const RequestDelegation = () => {
  const user = useUser();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const {
    data: profile,
    loading,
    error,
  } = useQuery({
    type: 'getOnly',
    resource: 'profile',
  });

  const [busy, setBusy] = useState(false);
  const [done, setDone] = useState(false);
  const [data, setData] = useState({});
  const setValue = (n, v) => setData({ ...data, [n]: v });
  const values = { ...profile, ...data };

  const request = (e) => {
    e.preventDefault();
    setBusy(true);
    const { name, email, phone, message } = Object.fromEntries(
      new FormData(e.target)
    );
    dataProvider
      .post('account/delegations/request', { name, email, phone, message })
      .then((res) => {
        console.info('request RESULT', { res, email, phone, e });
        notify('Request sent!', { type: 'success' });
        setDone(true);
        //e.target.reset();
      })
      .finally(() => setBusy(false));
  };

  if (!profile) return null;

  console.log('RequestDelegation', { user, profile, data });

  if (done)
    return (
      <>
        <strong>Request sent</strong>
        <p>Thanks. Our team will be in contact shortly.</p>
      </>
    );
  return !data ? (
    <>Loading...</>
  ) : (
    <>
      <strong>Request access</strong>
      <p>Fill in the form below to request access to an account</p>
      <form onSubmit={(e) => request(e)} disabled={busy}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '80px auto',
            gridTemplateRows: 'repeat(3, 40px) auto',
            rowGap: 20,
            marginBottom: 20,
          }}
        >
          <span>Name:</span>
          <input
            type="text"
            name="name"
            placeholder="Name"
            disabled={busy}
            value={values?.name}
            onChange={(e) => setValue('name', e.target.value)}
          />
          <span>Email:</span>
          <input
            type="email"
            name="email"
            placeholder="Email"
            disabled={busy}
            value={values?.email}
            onChange={(e) => setValue('email', e.target.value)}
          />
          <span>Phone:</span>
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            disabled={busy}
            value={values?.phone}
            onChange={(e) => setValue('phone', e.target.value)}
          />
          <span>Detail:</span>
          <div>
            <p>
              Please describe your requirements, tell us what account you need
              to access
            </p>
            <textarea
              name="message"
              placeholder="Please describe your request"
              disabled={busy}
              onChange={(e) => setValue('message', e.target.value)}
              rows="5"
              style={{ minHeight: 120, width: '100%', padding: 5 }}
            >
              {values?.message}
            </textarea>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" type="submit">
            Submit Request
          </Button>
        </div>
      </form>
    </>
  );
};

export const NoDelegations = () => {
  return <>You don't have access to any other accounts</>;
};

export default Delegations;
