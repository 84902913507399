import Loadable from 'react-loadable';
import Loading from 'theme/MyLoading';

const p = (s) => s.replace(/^(.*\/)?([^/]+)$/gi, '$2');

export const Load = (path, method) =>
  Loadable({
    loader: () =>
      import('views/' + path)
        .then((m) => {
          const fn = (window.fn = m[p(method || path)] || m['default']);
          //if (typeof fn === 'function') {
          //if (fn instanceof Function) {
          if (fn) {
            return fn;
          }
          console.error('Loadable import not a function', {
            path,
            method,
            fn,
            final_method: p(method || path),
          });
        })
        .catch((e) => {
          console.error('Loadable import error', { path, method, e });
        }),
    loading: Loading,
    delay: 300, // 0.3 seconds
  });

export const Page = (path, method) => Load('pages/' + path, method);
export const Action = (path, method) => Load('resources/' + path, method);
export const Resource = (path) => {
  return {
    Edit: Action(path, p(path) + 'Edit'),
    Create: Action(path, p(path) + 'Create'),
    Show: Action(path, p(path) + 'Show'),
    List: Action(path, p(path) + 'List'),
  };
};
