import * as React from 'react';
import { Route } from 'react-router-dom';
import * as async from 'lib/async';

const Forgot = async.Page('Forgot'); // import Forgot from 'views/Forgot';
const Reset = async.Page('Reset'); // import Reset from 'views/Reset';
const Register = async.Page('Register');
const Install = async.Page('Install');

const Contact = async.Page('Contact');
const Settings = async.Page('Settings');
const Delegations = async.Page('Delegations');
const Feedback = async.Page('Feedback');
//const Help = async.Page('Help');
const Book = async.Page('Book');
const WasteReport = async.Page('WasteReport');

export default [
  <Route exact path="/forgot" component={Forgot} noLayout key="forgot" />,
  <Route exact path="/reset" component={Reset} noLayout key="reset" />,
  <Route exact path="/register" component={Register} noLayout key="register" />,
  <Route exact path="/install" component={Install} key="install" />,

  // dirty hack is dirty, for backwards compatibility
  <Route exact path="/client/forgot" component={Reset} noLayout key="client" />,
  /* this is the only hack, no need to create redirects */

  // business routes
  <Route exact path="/feedback" component={Feedback} key="feedback" />,
  <Route exact path="/waste-report" component={WasteReport} key="waste" />,
  <Route exact path="/book" component={Book} key="book" />,
  <Route exact path="/contact" component={Contact} key="contact" />,
  <Route exact path="/settings" component={Settings} key="settings" />,
  <Route exact path="/delegations" component={Delegations} key="delegations" />,
  //<Route exact path="/help" component={Help} key="help"/>,
];
