import { ParseContext, EnvironmentConstants } from './context'; // '@fyne/ui/context';
const context = ParseContext(process.env);
const constants = EnvironmentConstants(process.env);
const baseUrl =
  context.API_BASE || 'https://' + context.domain + (context.path || '/x/api');

//console.log('Config', { baseUrl, env: process.env, constants, context, parsed: ParseContext(process.env) });

export const Config = {
  ...context,
  baseUrl,
  constants,
  // "client": "dashboard",
  // "apikey": "jwt?",
  // "domain": "domain.com"
};

export default Config;
