const SET_BOOK_CLEAR = 'SET_BOOK_CLEAR';
const SET_BOOK_WORKING = 'SET_BOOK_WORKING';
const SET_BOOK_CONFIRMED = 'SET_BOOK_CONFIRMED';

const initialState = {
  working: false,
  confirmed: null,
};

export default (previousState = initialState, { type, payload }) => {
  if (type === SET_BOOK_CLEAR) {
    return initialState;
  }

  if (type === SET_BOOK_WORKING) {
    return {
      ...previousState,
      working: payload,
    };
  }

  if (type === SET_BOOK_CONFIRMED) {
    return {
      ...previousState,
      confirmed: payload,
    };
  }

  return previousState;
};

export const bookClear = (payload) => {
  return {
    type: SET_BOOK_CLEAR,
    payload,
  };
};

export const bookWorking = (payload) => {
  return {
    type: SET_BOOK_WORKING,
    payload,
  };
};

export const bookConfirmed = (payload) => {
  return {
    type: SET_BOOK_CONFIRMED,
    payload,
  };
};
