import { createBrowserHistory } from 'history';
// import Config from './config'

// console.log('HISTORY', {env:process.env,Config})

// https://github.com/marmelab/react-admin/pull/1849#issuecomment-391507494
// https://stackoverflow.com/a/50497983
export default createBrowserHistory({
  window,
  // basename: Config.base || './',
  // basename: process.env.public_url,
  // basename: context.BASE_PATH,
});
