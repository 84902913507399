import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

//ReactDOM.render(<App />, document.getElementById('root'));
import {
  SignedIn,
  SignedOut,
  //  RedirectToSignIn,
  SignIn,
  ClerkProvider,
} from '@clerk/clerk-react';

// Get the Frontend API from the environment
const clerkFrontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;

const Render = (element, options = {}) => {
  //console.log('weclea-dashbo AppRender', {element,options,App,});
  if (!element) return;

  ReactDOM.render(
    // <React.StrictMode>
    <ClerkProvider frontendApi={clerkFrontendApi}>
      <SignedIn>
        <App {...options} />
      </SignedIn>
      <SignedOut>
        {/* <RedirectToSignIn /> */}
        <SignIn />
      </SignedOut>{' '}
    </ClerkProvider>,
    // </React.StrictMode>
    element
  );
};

if (window.fwx) {
  window.fwx.app.install('weclea-dashbo', Render);
} else {
  Render(document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); //console.log);
